<template>
  <div>
    <el-collapse  v-model="filter"  @change="handleChange">
      <el-collapse-item  title="Фильтр" name="p1" >
        <el-row class="m-0 filter">
          <el-col :span="16" class="ml-2 filter--container">
            <span class="mr-2" style="min-width:8rem;">Дата загрузки:</span>
            <el-date-picker type="date"
                            size="small"
                            placeholder="дд.мм.гггг"
                            v-model="dateTimeFilterFrom"
                            v-mask="'##.##.####'"
                            format="dd.MM.yyyy"
                            style="width: 25%;"
                            :picker-options="datePickerOptions" />
            <span class="m-2">-</span>
            <el-date-picker type="date"
                            size="small"
                            placeholder="дд.мм.гггг"
                            v-model="dateTimeFilterTo"
                            v-mask="'##.##.####'"
                            format="dd.MM.yyyy"
                            style="width: 25%;"
                            :picker-options="datePickerOptions" />
          </el-col>
        </el-row>
      </el-collapse-item>
    </el-collapse>
    <el-row class="m-0" :gutter="20">
      <!-- <template v-if="userHasRole('PkAnalysisXmlImport')"> -->
        <!-- <el-col :span="4">
          <el-button @click="handleUploadDialogOpen" type="text" icon="el-icon-circle-plus-outline">Загрузить</el-button>
        </el-col> -->
        <!-- <el-col :span="24">
          <pagination class="text-center"
                      :total-count="totalCount"
                      :page-size.sync="pageSize"
                      :current-page.sync="pageNumber"
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange" />
        </el-col>
      </template>
      <template v-else> -->
        <el-col :span="24">
          <pagination class="text-center"
                      :total-count="totalCount"
                      :page-size.sync="pageSize"
                      :current-page.sync="pageNumber"
                      @size-change="handleSizeChange"
                      @current-change="handleCurrentChange" />
        </el-col>
      <!-- </template> -->
    </el-row>
    <div>
      <el-table style="width: 100%;"
                v-loading="loading"
                :data="aipUploads"
                :height="tableHeight"
                :default-sort="{prop: 'startTime', order: 'descending'}"
                @sort-change="handleSortChange">
        <el-table-column
                         min-width="12">
          <template slot-scope="scope">
            <div v-if="!scope.row.isSuccessful && scope.row.endTime === null"
                 v-loading="true"
                 class="loading-div"
                 element-loading-spinner="el-icon-loading">
            </div>
            <div v-else-if="scope.row.isSuccessful">
              <router-link v-if="!scope.row.isAcceptCompleted && userHasRole('EventsMatch')" :to="{ name: 'EventsMatch', params: { id: scope.row.id } }">
                <el-button style="width: 100%" size="small" class="p-2">
                  <template >
                    Сопоставить 
                  </template>
                </el-button>
              </router-link>
              <router-link v-else-if="scope.row.isAcceptCompleted && userHasRoleGroup('ExternalUser')" :to="{ name: 'Events',  query: { fileName: getFileName(scope) } }">
                <el-button style="width: 100%" size="small" class="p-2"> 
                  <template >
                    Просмотр
                  </template>
                </el-button>
              </router-link>
              <router-link v-else-if="scope.row.isAcceptCompleted && !scope.row.isExternal && userHasRole('EventsMatch')" :to="{ name: 'Events',  query: { fileName: getFileName(scope) } }">
                <el-button style="width: 100%" size="small" class="p-2"> 
                  <template >
                    Просмотр
                  </template>
                </el-button>
              </router-link>
              <router-link v-else-if="scope.row.isAcceptCompleted && scope.row.isExternal && userHasRole('EventsMatch')" :to="{ name: 'EventMapping2',  query: { fileName: getFileName(scope) } }">
                <el-button style="width: 100%" size="small" class="p-2"> 
                  <template >
                    Просмотр
                  </template>
                </el-button>
              </router-link>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="status"
                         label="Статус"
                         sortable="custom"
                         min-width="15">
          <template slot-scope="scope">
            <div v-if="scope.row.endTime === null">
              <div v-if="!userHasRole('EventsMatch')"
                   v-loading="true"
                   class="loading-div"
                   element-loading-spinner="el-icon-loading">
              </div>
              <div v-else>
                Не сопоставлено
              </div>
            </div>
            <div v-else-if="scope.row.isSuccessful">
              <template v-if="!scope.row.isAcceptCompleted">
                Не сопоставлено
              </template>
              <template v-else>
                Готово к сопоставлению
              </template>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="startTime"
                         label="Дата начала"
                         sortable="custom"
                         min-width="15"
                         :formatter="dateTimeRenderer">
        </el-table-column>
        <el-table-column prop="endTime"
                         label="Дата окончания"
                         sortable="custom"
                         min-width="15"
                         :formatter="dateTimeRenderer">
        </el-table-column>
        <el-table-column prop="initiatorLogin"
                         label="Логин"
                         sortable="custom"
                         min-width="15">
        </el-table-column>
        <el-table-column prop="organizationName"
                         label="От имени"
                         sortable="custom"
                         min-width="15">
        </el-table-column>
        <el-table-column prop="fileName"
                         label="Имя файла"
                         sortable="custom"
                         min-width="20">
        </el-table-column>
        <el-table-column prop="fileType"
                         label="Тип файла"
                         sortable="custom"
                         min-width="20">
        </el-table-column>
        <el-table-column prop="resultDetails"
                         label="Доп. информация"
                         sortable="custom"
                         min-width="30">
          <template slot-scope="scope" v-if="scope.row.resultDetails">
            <div class="exception-container">
              {{ scope.row.resultDetails.length > 114 ? scope.row.resultDetails.slice(0,115) + '...' : scope.row.resultDetails}}
              <el-button v-if="scope.row.resultDetails.length > 114" type="text" icon="el-icon-d-arrow-right" class="exception__button"
                @click="open(scope.row.resultDetails)"></el-button>
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="userHasRole('EventsMatch') && !userFilter" width="50">
          <template slot-scope="scope">
            <el-button type="text" icon="el-icon-delete"
                       @click="handleDelete(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- <upload-modal v-if="dialogVisible"
                  v-model="dialogVisible"
                  :file-text="fileText"
                  @start-upload="getUploads" /> -->
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import debounce from 'lodash.debounce';
  import signalrService from '@/utilities/signalrService';
  import userUploadApi from '@/api/eventsAndIncidents/userUpload';
  import pagination from '@/components/Ui/Pagination';
  import formatters from '@/mixins/formatters.js';
  import tableResize from '@/mixins/tableResize.js';
  import dateHelpers from '@/mixins/dateHelpers';
  import moment from 'moment';
  import UserSettingsService from '@/service/userSettingsService';
  import { MessageBox } from 'element-ui';
  // import UploadModal from './UploadModal';

  export default {
    name: 'UploadNsiList',
    props: {
      id: { type: String, default: () => null },
      // importTypeId: { type: Number, default: () => 0 },
      // fileText: { type: String, default: () => 'Выберите файл' },
      // remoteParseMethod: { type: Function, default: () => null }
    }, 
    mixins: [formatters, tableResize, dateHelpers],
    components: { pagination },
    data() {
      return {
        filter: [''],
        tableSizeConstant: 30,
        importTypeId: 401,
        fileText: 'Выберите XML-файл формата ПК "Анализ 2009":',
        loading: false,
        dialogVisible: false,
        dialogGostVisible: false,
        aipUploads: [],
        pageNumber: 1,
        pageSize: 20,
        totalCount: 0,
        sortField: 'startTime',
        sortDesc: true,
        dateTimeFilterFrom: '',
        dateTimeFilterTo: '',
        userFilter: false
      };
    },
    computed: {
      ...mapGetters('identity', ['userHasRole', 'userHasRoleGroup', 'user', 'userTimeZone'])
    },
    async created() {
      if (UserSettingsService.has('uploadNsiList_filter_open', this.user.id)) {
        this.filter = ['p1'];
      }
      this.userFilter = this.$route.path === '/events-incidents/user-uploads';
      const dateTimeFilterFromDefault = await this.getNow();
      dateTimeFilterFromDefault.setDate(dateTimeFilterFromDefault.getDate() - 14);
      this.dateTimeFilterFrom = dateTimeFilterFromDefault;
    },
    async mounted() {
      signalrService.methodSubscribe('ProcessEnd', this.onProcessEnd);
    },
    watch: {
      dateTimeFilterFrom: {
        handler() {
          debounce(this.getUploads, 500)();
        }
      },
      dateTimeFilterTo: {
        handler() {
          debounce(this.getUploads, 500)();
        }
      },
    },
    methods: {
      getFileName(scope) {
        return `${moment(scope.row.startTime).add(this.userTimeZone, 'hours').format('DD.MM.YYYY HH:mm:ss')} "${scope.row.fileName}"`;
      },
      handleChange(val) {
        if (val.find((el) => el === 'p1')) {
          UserSettingsService.set('uploadNsiList_filter_open', true, this.user.id);
        } else {
          UserSettingsService.delete('uploadNsiList_filter_open', this.user.id);
        }
      },
      async getUploads() {
        this.loading = true;
        const res = await userUploadApi.getUserUploads(
          this.pageNumber,
          this.pageSize,
          this.sortField,
          this.sortDesc,
          this.dateTimeFilterFrom ? moment(this.dateTimeFilterFrom).format('YYYY-MM-DDTHH:mm:ss') : null, // this.dateTimeFilterFrom : null,
          this.dateTimeFilterTo ? moment(this.dateTimeFilterTo).format('YYYY-MM-DDTHH:mm:ss') : null,
          this.userFilter
        );
        if (res.data) {
          this.aipUploads = res.data.items;
          this.totalCount = res.data.totalCount;

          this.tableHeight = this.getTableHeight();
        }
        this.loading = false;
      },
      async handleSizeChange() {
        this.pageNumber = 1;
        await this.getUploads();
      },
      async handleCurrentChange() {
        await this.getUploads();
      },
      async handleSortChange(event) {
        this.sortField = event.prop;
        this.sortDesc = event.order === 'descending';
        await this.getUploads();
      },
      handleUploadDialogOpen() {
        this.dialogVisible = true;
      },
      async onProcessEnd(process) {
        if (process != null) {
          await this.getUploads();
        }
      },
      statusRenderer(row, column, cellValue) {
        if (cellValue === 0) return 'Не загружено';
        if (cellValue === 1) return 'Не сопоставлено';
        if (cellValue === 2) return 'Готово к сопоставлению';

        return null;
      },
      async handleDelete(item) {
        const checkUs = await userUploadApi.checkUserUploads(item.id);
        let mess = 'Вы действительно хотите удалить запись?';
        if (checkUs.data.length > 0) {
          let text = `Будет удалено ${checkUs.data.length} ${checkUs.data.length === 1 ? 'событие' : 'событий'  } с №`;
          checkUs.data.forEach((element) => {
          text += `<br>${element}`;
           mess = `Вы действительно хотите удалить запись? <p>${text}</p>`;
          });
        }
        this.$confirm(mess, 'Удаление записи!', {
          confirmButtonText: 'ОК',
          cancelButtonText: 'Отмена',
          dangerouslyUseHTMLString: true,
          customClass: 'custom-confirm',
          type: 'warning',
        }).then(async () => {
          this.loading = true;

          const res = await userUploadApi.deleteUserUpload(item.id);
          if (!res.data.succeeded) {
            this.$alert(res.data.resultMessage, 'Ошибка', {
              confirmButtonText: 'OK',
              type: 'warning',
              customClass: 'alert-30',
            });
          } else {
            await this.getUploads();
          }

          this.loading = false;
        });
      },
      open(text) {
        MessageBox.alert(text.replace(/\n/g, '<br>'), 'Доп. информация', {
            confirmButtonText: 'OK',
            customClass: 'exception__modal',
            dangerouslyUseHTMLString: true
          });
      }
    }
  };
</script>

<style scoped>
</style>
